import React, { useState, useEffect } from "react"
import tw, { styled } from 'twin.macro'
import Button from "./Button"

const Container = styled.form`
    ${tw`
        flex flex-col
        w-full h-full 
    `}
    z-index: 2;
`

const InputText = styled.input`
    ${tw`
        block
        border-2 border-gray-300 rounded
        py-3 px-4
        mt-8
        w-full sm:w-4/6 lg:w-3/4
    `}

    :focus {
        ${tw`
            outline-none
        `}
        border-color: orange;
    }
`

const StatusMessage = styled.div`
    > p {
        ${tw`
            absolute
        `}

        color: orange;
    }
    
`

const SubmitBtn = styled(Button)`
    ${tw`
        mt-16 sm:mt-12 max-w-max
        border-2
    `}

    border-color: darkorange;
    background-color: darkorange;

    :hover {
        color: darkorange;

        ${tw`
            bg-white
        `}
    }
`

const NewsletterForm = () => {
    const [email, setEmail] = useState('')
    const [sentStatus, setSentStatus] = useState('')

    async function handleSubmit(e) {
        e.preventDefault()

        if (e.target.disabled) {
            return
        }

        e.target.disabled = true

        const reqBody = {
            "list_ids": [
                process.env.GATSBY_SENDGRID_NEWSLETTER_LIST_ID
            ],
            contacts: [
                { email }
            ]
        }

        const res = await fetch(
            "https://api.sendgrid.com/v3/marketing/contacts",
            {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_SENDGRID_API_KEY}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(reqBody),
            }
        )

        if (res.ok) {
            setSentStatus("Thanks for subscribing!")
            setEmail("")

            setTimeout(() => setSentStatus(""), 5000);
        } else {
            setSentStatus("Something went wrong, please try again")
        }

        e.target.disabled = false;
    }

    return (
        <Container onSubmit={handleSubmit}>
            <p>Subscribe to our newsletter to keep up with the latest LT3 ATG news and articles</p>
            <InputText
                id="email"
                placeholder="Email"
                type="email"
                name="email"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <StatusMessage>
                <p>{sentStatus}</p>
            </StatusMessage>
            <SubmitBtn type="submit">
                Subscribe
            </SubmitBtn>
        </Container>
    )
}

export default NewsletterForm
