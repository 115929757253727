import React, { useState } from 'react'
import { graphql, Link } from "gatsby"
import tw, { styled, css } from 'twin.macro'

import Layout from "../components/Layout"
import Section from '../components/Section'
import Hero from '../components/Hero'
import NewsletterForm from '../components/NewsletterForm'

const Wrapper = styled.div`
    ${tw`
        w-full
        text-lg
        relative
    `}
`

const UnderlinedLink = styled(Link)`
    ${tw`
        underline hover:no-underline
    `}
`

const PostsContainer = styled.div`
    ${tw`
        lg:w-3/5 
        lg:float-left
    `}

    > h3 {
        ${tw`
            font-medium
        `}
    }
`

const Title = styled.h3`
    ${tw`
        mb-8
    `}
`

const TitleLink = styled(Link)`
    ${({ to }) => to && css`
        ${tw`
            hover:underline
        `}
       
        color: darkorange;
    `}
`

const Article = styled.article`
    ${tw`
        mb-16 
        lg:mr-28
    `}
`

const ArticleLink = styled(Link)`
    > h5 {
        ${tw`
            underline
        `}
    }

    :hover > h5 {
        ${tw`
            no-underline
        `}
    }

    > p {
        ${tw`
            mt-2
        `}
    } 
`

const ArticleMeta = styled.div`
    ${tw`
        mt-4
        text-base
    `}
`

const SidePanel = styled.div`
    ${tw`
        lg:w-2/5
        lg:float-right
        mt-24
    `}
`

const SidePanelSection = styled.div`
    ${tw`
        mb-16
    `}
    
    > p:first-child {
        color: darkorange;

        ${tw`
            uppercase font-medium
            mb-4
        `}
    }
`

const TopPost = styled.div`
    ${tw`
        mb-8
    `}

    p {
        ${tw`
            mt-2
            text-base
        `}
    }
`

const Category = styled(Link)`
    ${tw`
        mb-4
        block w-max
        hover:underline
    `}
`

const Pagination = styled.nav`
    ${tw`
        text-xl
        w-full
        flex justify-center
        lg:absolute lg:bottom-0 lg:translate-y-full
    `}
`

const PageLink = styled(Link)`
    ${tw`
        p-2
    `}

    color: ${props => props.active ? "darkorange" : "black"};

    ${({ disabled }) => disabled && tw`
        pointer-events-none
        text-gray-300
    `}
`

const BlogPage = ({
    data: {
        markdownRemark: {
            frontmatter: { seoBlogLists, hero }
        },
        allBlogs: { edges: posts },
        topBlogs: { edges: topPosts }
    },
    pageContext: { currentPage, numPages, activeCategories, categories },
    location
}) => {
    function getUrl(category) {
        let retUrl = "/blog"
        if (category)
            retUrl += "/" + category.replace(/\s+/g, '-').toLowerCase();

        return retUrl;
    }

    const category = /\/blog(\/\d+)?(?!\/\D+)/g.test(location.pathname) ? "" : categories[0];

    let seo = seoBlogLists.find(seoData => seoData.category === "Default");
    if (category)
        seo = seoBlogLists.find(seoData => seoData.category === category) || seo;

    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? getUrl(category) : `${getUrl(category)}/${(currentPage - 1)}`
    const nextPage = `${getUrl(category)}/${(currentPage + 1)}`
    const maxPageNumbersVisible = 5;

    let lowerPageLimit = currentPage - 2;
    let upperPageLimit = currentPage + 2;

    if (lowerPageLimit <= 0 || numPages <= maxPageNumbersVisible)
        lowerPageLimit = 1;

    if (upperPageLimit > numPages || numPages <= maxPageNumbersVisible)
        upperPageLimit = numPages;

    let pageNumbers = [];
    for (let i = lowerPageLimit; i <= upperPageLimit; i++) {
        pageNumbers.push(i);
    }

    return (
        <Layout title={seo?.title} description={seo?.description}>
            <Section>
                <Hero data={hero} />
            </Section>

            <Section>
                <Wrapper>
                    <PostsContainer>
                        <Title>
                            <TitleLink to={category ? "/blog" : null}>All Posts</TitleLink>{category && ` / ${category}`}
                        </Title>
                        {posts.length > 0 ? (
                            posts.map(({ node: { excerpt, fields, frontmatter: { title, categories, date } } }, postIdx) => (
                                <Article key={postIdx}>
                                    <ArticleLink to={fields.slug}>
                                        <h5>{title}</h5>
                                        <p>{excerpt}</p>
                                    </ArticleLink>
                                    <ArticleMeta>
                                        {categories.map((category, idx) => (
                                            <>
                                                <UnderlinedLink to={getUrl(category)}>{category}</UnderlinedLink>{idx < categories.length - 1 ? ", " : " "}
                                            </>
                                        ))}
                                        &#9679; <span>{date}</span>
                                    </ArticleMeta>
                                </Article>
                            ))
                        ) : (
                            <Article>
                                <h5>No blog posts available, please check back in the future.</h5>
                            </Article>
                        )}
                        {numPages > 1 && (
                            <Pagination>
                                <PageLink to={prevPage} rel="prev" disabled={isFirst}>
                                    &#171;
                                </PageLink>
                                {pageNumbers.map((pageNo, idx) => (
                                    <PageLink
                                        key={`page-number-${pageNo}`}
                                        to={pageNo === 1 ? getUrl(category) : `${getUrl(category)}/${pageNo}`}
                                        active={currentPage === pageNo}
                                    >
                                        {pageNo}
                                    </PageLink>
                                ))}
                                <PageLink to={nextPage} rel="next" disabled={isLast}>
                                    &#187;
                                </PageLink>
                            </Pagination>
                        )}
                    </PostsContainer>
                    <SidePanel>
                        <SidePanelSection>
                            <p>Newsletter</p>
                            <NewsletterForm />
                        </SidePanelSection>
                        <SidePanelSection>
                            <p>Top Posts</p>
                            {topPosts.length > 0 ? (
                                topPosts.map(({ node: { fields, frontmatter: { title, date } } }, postIdx) => (
                                    <TopPost>
                                        <UnderlinedLink to={fields.slug}>{title}</UnderlinedLink>
                                        <p>{date}</p>
                                    </TopPost>
                                ))
                            ) : (
                                <p>No highlighted {`${category} `}posts at the moment</p>
                            )}
                        </SidePanelSection>
                        <SidePanelSection>
                            <p>Categories</p>
                            {activeCategories.length > 0 ? (
                                activeCategories.map((category, postIdx) => (
                                    <Category to={getUrl(category)}>{category}</Category>
                                ))
                            ) : (
                                <p>All blog categories contain no posts at the moment</p>
                            )}
                        </SidePanelSection>
                    </SidePanel>
                </Wrapper>
            </Section>
        </Layout>
    )
}

export default BlogPage

export const pageQuery = graphql`
    query blogQuery($skip: Int!, $limit: Int!, $categories: [String]) {
        markdownRemark(frontmatter: { templateKey: { eq: "blog-page" } }) {
            frontmatter {
                seoBlogLists {
                    category
                    title 
                    description
                }
                hero {
                    preheader
                    header {
                        text
                        gradient {
                            top
                            bottom
                        }
                    }
                    subheader
                    sideImages {
                        left {
                            relativePath
                        }
                        right {
                            relativePath
                        }
                    }
                }
            }
        }
        allBlogs: allMarkdownRemark(
            filter: {
                frontmatter: { templateKey: { eq: "blog-post" }, publish: { eq: true }, categories: { in: $categories } }
            }
            sort: { fields: frontmatter___date, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "MMM Do, YYYY")
                        categories
                    }
                    excerpt(pruneLength: 130)
                }
            }
        }
        topBlogs: allMarkdownRemark(
            filter: {
                frontmatter: { templateKey: { eq: "blog-post" }, publish: { eq: true }, categories: { in: $categories }, top: { eq: true} }
            }
            sort: { fields: frontmatter___date, order: DESC }
            limit: 3
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "MMM Do, YYYY")
                    }
                }
            }
        }
    }
`